import React, { useEffect } from 'react';
import styled from 'styled-components';
import Layout from '../components/Layout';
import { colors } from '../styles/theme';
import { TextBlock } from '../styles/shared';
import { storeReferralData } from '../utils/helpers';
import SEO from '../components/SEO';

const TermsPage = ({ location }) => {
  // store referral params
  useEffect(() => {
    if (location.search) {
      const params = new URLSearchParams(location.search);
      storeReferralData(params);
    }
  }, []);
  return (
    <>
      <SEO title='Terms and Conditions' />
      <Layout>
        <Container>
          <TextBlock>
            <h1>Terms and Conditions</h1>
            <h3>UPDATED: April 25. 2021</h3>
            <p>
              Neu Community, Inc. (which together with its builder and leasing
              affiliates, are collectively referred to herein as "Neu," "we,"
              "us" or "our") provides these Terms of Use. These Terms of Use
              describe the use of this website (this "Website"), on mobile
              sites, applications, and mobile interactive features ("Mobile
              Applications"), on our social media pages ("Social Media Pages"),
              and in connection with email messages that we may send to you.
              This Website, our Mobile Applications and our Social Media Pages
              are collectively referred to in these Terms of Use as our "Sites."
              As used herein, the words "user," "you," and "your" mean users of
              this Website or our other Sites.
            </p>
            <p>
              By accessing or using our Sites, you agree to the provisions of
              these Terms of Use.
            </p>
            <h2>OWNERSHIP AND USE OF CONTENT.</h2>
            <p>
              The Sites and their contents are owned by Neu or used under
              license from others with all rights reserved. The reproduction,
              re-creation, retransmission or other use of any of the material on
              the Sites, whether text, visual image, audio, video, or computer
              software, or the posting or framing on other websites without the
              express prior written permission of Neu is expressly prohibited;
              provided, however, that you may print in hard copy portions of the
              Sites for your personal home buying purposes and you may utilize
              functionality provided on the Sites to post interactive blueprints
              to social media platforms.
            </p>
            <h2>FAIR HOUSING.</h2>
            <p>
              Neu Community, Inc. and its affiliates recognize the importance
              and significance of fair housing. We conduct business in
              accordance with all applicable federal, state and local fair
              housing laws. It is our policy to provide equal professional
              service to all persons regardless of race, color, religion, sex,
              handicap, familial status or national origin. Models do not
              reflect a preference for homebuyers.
            </p>
            <h2>CLAIMS OF COPYRIGHT INFRINGEMENT.</h2>
            <p>
              The Digital Millennium Copyright Act of 1998 (the "DMCA") provides
              recourse for copyright owners who believe in good faith that
              materials on our Sites infringe their rights. If you believe that
              material on our Sites infringes your copyright, you or your agent
              may send us a notice requesting that we remove or block the
              material. Notices must meet the requirements of the DMCA. Notices
              may be sent to us at: Neu Community, Inc., Attn: Chief Legal
              Officer, 1019 S Heatherwilde #150 Pflugerville, TX 78660. There
              are penalties for false claims under the DMCA.
            </p>
            <h2>LINKS TO OTHER WEBSITES.</h2>
            <p>
              We may provide links from our Sites, through advertisements or
              otherwise, to other websites, or applications including various
              social media platforms. Those websites, applications and social
              media platforms have their own privacy policies and practices.
              These Terms of Use do not address, and we are not responsible for,
              their content, privacy policies or practices or security and
              safeguarding practices. The inclusion of a link on any of our
              Sites does not imply endorsement of the linked website or
              application by us or any of our affiliates. We are not responsible
              for the collection, usage or sharing practices or policies of
              other companies or platforms including Facebook, Twitter,
              Instagram, Google, or Adobe, to mention just a few. Please visit
              those websites to review their privacy policies and practices.
            </p>
            <h2>LIMITATION OF LIABILITY AND DISCLAIMER.</h2>
            <p>
              Neu endeavors to keep the information on the Sites current and up
              to date. However, some of the information on the Sites may be
              inaccurate or incomplete, or may contain typographical errors. Neu
              does not warrant that the content of the Sites is error free or
              that its service in providing the Sites will be uninterrupted.
              Your use of the Sites is at your own risk. You agree that Neu is
              not liable for any damages relating to the use of, or reliance
              upon, the content of the Sites, including without limitation
              damage to your computer equipment or other property from
              downloading material from the Sites.
            </p>
            <p>
              TO THE FULLEST EXTENT ALLOWED BY APPLICABLE LAW, YOU HEREBY WAIVE,
              DISCLAIM AND RELEASE NEU COMMUNITY, INC., ITS SUBSIDIARIES AND
              AFFILIATES, AND THEIR RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES,
              AGENTS, SUCCESSORS, AND ASSIGNS, FROM ALL CLAIMS OF ANY KIND
              INCLUDING ANY AND ALL CLAIMS FOR ACTUAL, INCIDENTAL,
              CONSEQUENTIAL, PUNITIVE OR EXEMPLARY DAMAGES, ATTORNEYS' FEES AND
              COSTS, OR CLAIMS FOR INTEREST, AND WHETHER IN CONTRACT, WARRANTY,
              TORT (INCLUDING NEGLIGENCE WHETHER ACTIVE, PASSIVE OR IMPUTED),
              PRODUCT LIABILITY, STRICT LIABILITY OR OTHER THEORY), RELATED
              DIRECTLY OR INDIRECTLY TO, OR ARISING FROM, YOUR ACCESS TO THE
              SITES OR YOUR USE OF ANY INFORMATION OR MATERIAL CONTAINED
              THEREIN. WITHOUT LIMITING THE FOREGOING, EVERYTHING ON THE SITES
              IS PROVIDED TO YOU "AS IS" WITHOUT WARRANTY OF ANY KIND, EITHER
              EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE IMPLIED
              WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE.
            </p>
            <p>
              THESE DISCLAIMERS AND LIMITATIONS MAY NOT AFFECT YOUR CONSUMER
              RIGHTS OR LIMIT LIABILITY TO THE EXTENT THAT IT CANNOT BE LIMITED
              BY LAW.
            </p>
            <h2>LAW AND JURISDICTION.</h2>
            <p>
              These Terms of Use shall be governed by, construed and enforced in
              accordance with, and subject to, the laws of the State of Texas,
              without resort to its choice of law provisions and case law,
              except to the extent they are superseded or preempted by the laws
              of the United States or other jurisdictions. If any provision of
              these Terms of Use is determined to be invalid, unlawful, void or
              otherwise not enforceable, you agree that the remaining provisions
              of these Terms of Use will remain in full force and effect. By
              accessing our Sites, or using information on our Sites, you submit
              to the exclusive jurisdiction of the state courts in Tarrant
              County, Texas, or the United States Federal District Court,
              Northern District of Texas, to resolve disputes of any kind
              arising out of your reliance on the information on a Site, or any
              interaction with Neu involving a Site. Any claim you have with
              respect to a Site must be brought within two years and one day
              after the claim arises.
            </p>
            <p>
              IF THERE IS A CONFLICT BETWEEN THE LAW IN YOUR USUAL PLACE OF
              RESIDENCE AND THESE TERMS OF USE, YOUR RIGHTS UNDER APPLICABLE
              LOCAL LAW MAY PREVAIL.
            </p>
            <h2>SUCCESSORS AND TERMINATION.</h2>
            <p>
              These Terms of Use shall be binding upon and inure to the benefit
              of Neu and you and its and your respective heirs, representatives,
              successors and assigns. These Terms of Use shall remain in force
              and effect until terminated by Neu. In the event of termination of
              these Terms of Use, restrictions imposed upon you by these Terms
              of Use, including all disclaimers, waivers, releases and
              limitations of liability, shall survive.
            </p>
            <h2>VOID WHERE PROHIBITED.</h2>
            <p>
              The Sites are accessible by users throughout the world. Neu
              products, services and offers may not be available in your state
              or community. The information on the Sites is not an offer of
              products or services where Neu is not doing business or where
              prohibited by law.
            </p>
            <h2>CHANGES TO TERMS OF USE AND WAIVER.</h2>
            <p>
              Neu reserves the right to change these Terms of Use from time to
              time. Neu will notify you of changes to these Terms of Use by
              updating them on this Website and setting forth the date on which
              the update was made. We encourage you to retain a copy of these
              Terms of Use and periodically to check this Website for updates.
              Your use of our Sites indicates your acceptance of the latest
              version of these Terms of Use.
            </p>
            <p>
              No waiver of any provision in these Terms of Use shall be
              effective unless made in writing signed by Neu and shall not
              prevent any subsequent enforcement of these Terms of Use.
            </p>
            <h2>PRIVACY POLICY.</h2>
            <p>
              To view our Privacy Policy, please go to our Privacy Policy page.
            </p>
            <h2>CONTACTING US.</h2>
            <p>
              If you have any questions about our Terms of Use, you may contact
              us at:{' '}
              <a href='mailto:info@neucommunity.com'>info@neucommunity.com</a>.
            </p>
            <h2>EFFECTIVE DATE.</h2>
            <p>
              These Terms of Use were effective as of the date of the last
              update as set forth at the top of these Terms of Use.
            </p>
          </TextBlock>
        </Container>
      </Layout>
    </>
  );
};
const Container = styled.section`
  padding: var(--body-padding-y) var(--body-padding-x);
  a:hover {
    color: ${colors.blue};
    text-decoration: underline;
  }
`;

export default TermsPage;
